<script>
	export let src = null;
	export let alt = '';
	export let width = null;
	export let height = null;
	export let loading = 'lazy';
	export let noMeta = null;
	export let description = null;

	// function to sort image mimetype strings by preference avif > webp > png > jpeg
	// returns 1 if a is preferred, -1 if b is preferred, 0 if equal
	// (note: autopilot filled this in)
	function sortFormats(a, b) {
		const formats = ['avif', 'webp', 'png', 'jpeg'];
		const aIndex = formats.indexOf(a);
		const bIndex = formats.indexOf(b);
		if (aIndex > bIndex) {
			return 1;
		}
		if (aIndex < bIndex) {
			return -1;
		}
		return 0;
	}
</script>

{#if $$slots.description || description}
	<div
		class={[
			'!col-span-full mb-4 justify-items-center',
			$$props.class ?? '',
		].join(' ')}
	>
		<picture class="image w-full max-md:!col-span-full [&_img]:mb-2">
			<slot />
			{#if src?.sources}
				{#each Object.entries(src?.sources).sort(sortFormats) as [format, srcset]}
					<source {srcset} type={`image/${format}`} class="contents" />
				{/each}
				<img
					src={src?.img?.src}
					{alt}
					{loading}
					width={width ?? src?.img?.w}
					height={height ?? src?.img?.h}
					class={$$props.class ?? null}
				/>
			{/if}
			{#if typeof src == 'string'}
				<img
					{src}
					{alt}
					{width}
					{height}
					class={$$props.class ?? null}
					{loading}
				/>
			{/if}
		</picture>
		<slot name="description">
			<p class="font-bold">{description}</p>
		</slot>
	</div>
{:else}
	<picture class="picture w-full max-md:!col-span-full">
		<slot />
		{#if src?.sources}
			{#each Object.entries(src?.sources).sort(sortFormats) as [format, srcset]}
				<source {srcset} type={`image/${format}`} class="contents" />
			{/each}
			<img
				src={src?.img?.src}
				{alt}
				{loading}
				width={width ?? src?.img?.w}
				height={height ?? src?.img?.h}
				class={['image grid justify-items-center', $$props.class ?? ''].join(
					' ',
				)}
			/>
		{/if}
		{#if typeof src == 'string'}
			<img
				{src}
				{alt}
				{width}
				{height}
				class={['image grid justify-items-center', $$props.class ?? ''].join(
					' ',
				)}
				{loading}
			/>
		{/if}
	</picture>
{/if}
<svelte:head>
	{#if !noMeta && alt && src?.sources}
		<meta property="og:image" content={src?.img?.src} />
		<meta property="og:image:alt" content={alt} />
		<meta property="og:image:width" content={width ?? src?.img?.w} />
		<meta property="og:image:height" content={height ?? src?.img?.h} />
		<meta property="twitter:image" content={src?.img?.src} />
		<meta property="twitter:image:alt" content={alt} />
	{/if}
</svelte:head>
